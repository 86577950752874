export const signs = [
    "Abnormal vaginal discharge",
    "Bleeding between periods",
    "Difficult or painful urination",
    "Pain during intercourse",
    "Pelvic pain",
    "Postmenopausal bleeding",
    "Unintentional weight loss",
    "Vaginal bleeding after menopause",
    "Rectal Bleeding",
    "Stomach Cramps",
    "and more...",
];

export const plainOptions = ["Yes", "No"];

export const options = [
    {
        label: "Uterine Cancer",
        value: "uternineCancer",
    },
    {
        label: "Endometrial",
        value: "endometrial",
    },
    {
        label: "Ovarian Cancer",
        value: "ovarianCancer",
    },
    {
        label: "None of the above",
        value: "none",
    },
];