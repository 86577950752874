import { appconstants } from "./action-types";
const initialState = {
  isLoading: false,
};
const loadingReducer = (state = initialState, action) => {
  switch (action.type) {
    case appconstants.START_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case appconstants.STOP_LOADING:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
};
export { loadingReducer };
