import { Button, Col, Image, Row, Typography } from "antd";
import React from "react";
const { Text, Title } = Typography;

const SecondPage = () => {
  return (
    <div
      style={{
        paddingLeft: "2em",
        paddingRight: "2em",
        marginTop: "5rem",
        marginBottom: "5rem",
      }}
    >
      <Row>
        <Col
          xs={{ offset: 2, span: 20 }}
          sm={{ offset: 2, span: 20 }}
          md={{ offset: 4, span: 16 }}
          lg={{ offset: 6, span: 12 }}
        >
          <Title style={{ textAlign: "center" }}>
            Find Yourself a Lawyer That Will Fight For You and Your Rights!
          </Title>
        </Col>
      </Row>
      <Row gutter={16} style={{ justifyContent: "center" }}>
        <Col lg={8} style={{ textAlign: "center" }}>
          <Image
            preview={false}
            src="https://lawsuit-winning.com/hairstraightener1/img/logo-icon.png"
          />
          <Text
            style={{
              display: "block",
              color: "maroon",
              fontSize: "1.2em",
              fontWeight: 500,
            }}
          >
            Receive the Compensation You Deserve!
          </Text>
        </Col>
        <Col lg={8} style={{ textAlign: "center" }}>
          <Image
            preview={false}
            src="https://lawsuit-winning.com/hairstraightener1/img/logo-icon.png"
          />
          <Text
            style={{
              display: "block",
              color: "maroon",
              fontSize: "1.2em",
              fontWeight: 500,
            }}
            strong
          >
            FREE No Obligation Consultation - Pay Nothing!
          </Text>
        </Col>
        <Col lg={8} style={{ textAlign: "center" }}>
          <Image
            preview={false}
            src="https://lawsuit-winning.com/hairstraightener1/img/logo-icon.png"
          />
          <Text
            style={{
              display: "block",
              color: "maroon",
              fontSize: "1.2em",
              fontWeight: 500,
            }}
            strong
          >
            Your Contact Information and Your Claim are Confidential!
          </Text>
        </Col>
      </Row>
      <Row
        style={{
          justifyContent: "center",
          textAlign: "center",
          marginTop: "1.2em",
          marginBotto: "1.2em",
        }}
      >
        <Col xs={22} sm={20} md={18} lg={16}>
          <Button
            type="primary"
            href="/#contactForm"
            size="large"
            style={{ marginBottom: "1.2em" }}
          >
            GET HELP NOW
          </Button>
          <Text
            style={{ display: "block", fontSize: "1.2em", fontWeight: 500 }}
          >
            You may claim millions of dollars for your pain and suffering. You
            probably didn't know you could be owed money for your situation, and
            it is time to speak up! DON'T LET YOU OR YOUR FAMILY SUFFER in
            silence. Submit your information today for a <Button type="text" href="/#contactForm">FREE CLAIM REVIEW »</Button>
          </Text>
        </Col>
      </Row>
    </div>
  );
};

export default SecondPage;
