import { Col, Divider, Row, Typography } from "antd";
import React from "react";
import { Link } from "react-router-dom";
const { Title } = Typography;

const FifthPage = () => {
  return (
    <div style={{ marginTop: "5rem", marginBottom: "3rem" }}>
      <Row justify={"center"}>
        <Col xs={22} sm={20} md={18} lg={16} style={{ paddingBottom: "2em" }}>
          <Title style={{ margin: 0 }}>IN THE NEWS</Title>
          <Divider />
          <Link to={"/"} style={{ display: "block", fontSize: "1.2em" }}>
            Hair straightening chemicals associated with higher uterine cancer
            risk
          </Link>
          <Link to={"/"} style={{ display: "block", fontSize: "1.2em" }}>
            Lawyers have filed at least nine lawsuits against L'Oréal USA Inc
          </Link>
          <Link to={"/"} style={{ display: "block", fontSize: "1.2em" }}>
            Ovarian Cancer Symptoms
          </Link>
        </Col>
      </Row>
    </div>
  );
};

export default FifthPage;
