import {
    Button,
    Card,
    Form,
    Radio,
    Row,
    Typography,
    Select,
    Col,
    Input,
    message,
} from "antd";
import React, { useEffect } from "react";
import styles from "../Styles/HairStraightener.module.css";
import { options, plainOptions, signs } from "../../Common/commondata";
import { CheckOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { hairSTraightenerAction } from "../state/actions";
const { Text, Title } = Typography;

const FirstPage = () => {
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const { isLoading } = useSelector(str => str.loadingReducer);
    const { addDetails, addDetailsErr } = useSelector(str => str.hairSTraightenerReducer)

    const generateYearOptions = () => {
        const currentYear = new Date().getFullYear();
        const startYear = 2004; // Start from 2004 and handle 2003 separately
        const years = [];

        for (let year = currentYear; year >= startYear; year--) {
            years.push({ value: year, label: year.toString() });
        }

        // Add "2003 or prior" option
        years.push({ value: "2003-or-prior", label: "2003 or prior" });

        return years;
    };

    const onFinish = (formData) => {
        try {
            // Convert year to string if not already
            const updatedData = {
                ...formData,
                year: String(formData.year),
            };
    
            dispatch(hairSTraightenerAction.addDetailsEvent(updatedData));
        } catch (error) {
            message.error("Something went wrong");
        }
    };

    useEffect(() => {
        if (addDetails) {
            message.success("Submitted");
            dispatch(hairSTraightenerAction.clearDetails());
            form.resetFields();
        }
        if (addDetailsErr) {
            message.error("Something went wrong. Please try again");
            dispatch(hairSTraightenerAction.clearDetails());
        }
    }, [addDetails, addDetailsErr])

    return (
        <Row gutter={[16, 16]} className={styles.firstPage} id="contactForm">
            <Col lg={{ span: 10, offset: 2 }}>
                <Title
                    style={{ fontSize: `clamp(1.6em, 3vw, 2.5em)`, textAlign: "center" }}
                >
                    Hair Straightener Lawsuits are now being filed!
                </Title>
                <Text
                    strong={true}
                    style={{
                        color: "maroon",
                        fontSize: `clamp(1.3em, 3vw, 1.8em)`,
                        textAlign: "center",
                    }}
                >
                    Missouri woman has sued L'Oréal alleging that their hair straightening
                    products caused her Uterine cancer. ***
                </Text>
                <div style={{ display: "flex", justifyContent: "center" }}>
                    <Card
                        size="small"
                        title={
                            <Title style={{ fontSize: "1.5em", textAlign: "center" }}>
                                Signs & Symptoms
                            </Title>
                        }
                        style={{ width: "300px" }}
                    >
                        {signs?.map((e) => (
                            <div>
                                <Button
                                    type="text"
                                    iconPosition="start"
                                    icon={<CheckOutlined />}
                                >
                                    {e}
                                </Button>
                            </div>
                        ))}
                    </Card>
                </div>
            </Col>
            <Col lg={{ span: 10 }}>
                <Card
                    styles={{ body: { padding: 0 }, header: { padding: 0 } }}
                    size="small"
                    title={
                        <div
                            style={{
                                textAlign: "center",
                                background: "#0492c2",
                                padding: "1.2em",
                                minWidth: "300px",
                            }}
                        >
                            <Text
                                style={{ display: "block", color: "yellow", fontSize: "1.2em" }}
                            >
                                Time is Limited
                            </Text>
                            <Text
                                style={{
                                    display: "block",
                                    color: "white",
                                    fontWeight: 700,
                                    fontSize: "1.3em",
                                }}
                            >
                                FILL OUT THE FORM BELOW
                            </Text>
                            <Text
                                style={{
                                    display: "block",
                                    color: "white",
                                    fontWeight: 800,
                                    fontSize: "1.4em",
                                }}
                            >
                                TO GET YOUR FREE CLAIM REVIEW
                            </Text>
                            <Text style={{ display: "block", fontSize: "1.2em" }}>
                                You may be entitled to financial compensation!
                            </Text>
                        </div>
                    }
                >
                    <div style={{ padding: "1.2em" }}>
                        <Form layout="vertical" form={form} onFinish={onFinish}>
                            <Form.Item
                                rules={[{ required: true }]}
                                name="lovedProducts"
                                label="Did you or a loved one use chemical straightener or hair relaxer products for at least one (1) year?"
                            >
                                <Radio.Group options={plainOptions} />
                            </Form.Item>
                            <Form.Item
                                name={"diagnosedConditions"}
                                rules={[{ required: true }]}
                                label="Were you or a loved one diagnosed with any of the following conditions?"
                            >
                                <Select options={options} />
                            </Form.Item>
                            <Form.Item
                                name={"year"}
                                rules={[{ required: true }]}
                                label="What year were you diagnosed?"
                            >
                                <Select
                                    options={generateYearOptions()}
                                    placeholder="Select a year"
                                />
                            </Form.Item>
                            <Form.Item
                                name={"lawyer"}
                                rules={[{ required: true }]}
                                label="Do you currently have a lawyerrepresenting your claim?"
                            >
                                <Radio.Group options={plainOptions} />
                            </Form.Item>
                            <Text
                                style={{
                                    backgroundColor: "#0492c2",
                                    display: "block",
                                    textAlign: "center",
                                    marginBottom: "1em",
                                    color: "white",
                                }}
                                strong={true}
                            >
                                Your Information is 100% Confidential
                            </Text>
                            <Row gutter={16}>
                                <Col span={12}>
                                    <Form.Item rules={[{ required: true }]} name={"firstName"}>
                                        <Input placeholder="First Name" />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item rules={[{ required: true }]} name={"lastName"}>
                                        <Input placeholder="Last Name" />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item rules={[{ required: true, type:"email" }]} name={"email"}>
                                        <Input placeholder="Email" />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item rules={[{ required: true }]} name={"phone"}>
                                        <Input placeholder="Phone" />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item rules={[{ required: true }]} name={"zip"}>
                                        <Input placeholder="Zip" />
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Form.Item>
                                        <Button
                                            style={{ width: "100%" }}
                                            size="large"
                                            type="primary"
                                            htmlType="submit"
                                            loading={isLoading}
                                        >
                                            SUBMIT MY CLAIM
                                        </Button>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>
                    </div>
                </Card>
            </Col>
        </Row>
    );
};

export default FirstPage;
