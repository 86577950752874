import { Button, Card, Col, Divider, Row, Typography } from "antd";
import React from "react";
const { Text, Title } = Typography;

const FourthPage = () => {
    const endometricalsigns = [
        "Abnormal vaginal discharge",
        "Bleeding between periods",
        "Difficult or painful urination",
        "Pain during intercourse",
        "Pelvic pain",
        "Postmenopausal bleeding",
        "Unintentional weight loss",
        "Vaginal bleeding after menopause",
    ];

    const ovarianSigns = [
        "Back Pain",
        "Bloating",
        "Fatigue",
        "Frequent or Urgent Need to Urinate",
        "Increased Abdominal Girth",
        "Pain or Pressure in the Pelvic Area",
    ];
    return (
        <div style={{ marginTop: '5rem', marginBottom: "5rem" }}>
            <Row gutter={[16, 32]} justify={"center"}>
                <Col xs={22} sm={18} md={10} lg={8}>
                    <Card bordered={false} title={<Text style={{ fontSize: "2em", textAlign: 'center' }}>Signs and Symptoms</Text>}>
                        <Text style={{ display: "block", fontSize: "1.2em" }}>
                            Uterine Cancer symptoms are like other health conditions. If you or
                            a loved one speculates about having uterus cancer it is suggested to
                            visit a healthcare professional immediately. A lump or protuberance
                            in between the abdominal area and pain in the pelvic area may be
                            indicative of uterus cancer. Also, if you notice unusual bleeding
                            and pain during sex is important to get checked out. Uterus Cancer
                            is a general term that describes cancer of your uterus. This
                            encompasses endometrial cancer and uterine sarcoma.
                        </Text>
                        <br />
                        <Text style={{ display: "block", fontSize: "1.2em" }}>
                            Other past research has shown evidence that hair straightener
                            chemicals are related to other hormone-sensitive cancers. Hence, we
                            will outline a list of symptoms pertaining to ovarian cancer in the
                            section below. Other symptoms of Uterus and Ovarian cancer include,
                            but are not limited to:
                        </Text>
                        <Title style={{ textDecoration: "underline", fontSize: "2em" }}>
                            Uterus & Endometrial Cancer
                        </Title>
                        <ul>
                            {endometricalsigns?.map((e) => (
                                <li style={{ fontSize: "1.2em" }}>{e}</li>
                            ))}
                        </ul>
                        <Title style={{ textDecoration: "underline", fontSize: "2em" }}>
                            Ovarian Cancer
                        </Title>
                        <ul>
                            {ovarianSigns?.map((e) => (
                                <li style={{ fontSize: "1.2em" }}>{e}</li>
                            ))}
                        </ul>
                    </Card>
                </Col>
                <Col xs={22} sm={18} md={10} lg={8}>
                    <Card hoverable title={<Text style={{ fontSize: "2em" }}>TAKE ACTION</Text>}>
                        <Text style={{ display: "block", fontSize: "1.2em", marginBottom:'1.2em' }}>
                            Uterine cancer diagnoses have increased over the last two decades.
                            This type of cancer was only 3% of all new cancer cases,
                            exponential increases have raised concern over women’s health. A
                            recent government NIH study revealed a connection between women
                            with uterine cancer and the use of chemical hair straighteners. As
                            of now, 9 lawsuits were filed against L'Oréal USA Inc. The suit
                            against L'Oréal claims that the product caused cancer and other
                            health problems. If you or a loved one has been diagnosed with
                            uterine cancer, ovarian cancer, and or breast cancer after using
                            chemical hair straighteners or relaxers then do not hesitate to
                            contact our legal team. You might be eligible for financial
                            compensation for your pain and suffering. Fill out a no-cost claim
                            review to see if you are eligible to receive compensation. –
                            complete the free and easy claim review form today.
                        </Text>
                        <Button size="large" type="primary" href="/#contactForm">GET HELP NOW!</Button>
                    </Card>
                </Col>
            </Row>
        </div >
    );
};

export default FourthPage;
