import React from "react";
import FirstPage from "./Components/FirstPage";
import SecondPage from "./Components/SecondPage";
import ThirdPage from "./Components/ThirdPage";
import FourthPage from "./Components/FourthPage";
import FifthPage from "./Components/FifthPage";

const HairStraightener = () => {
    return (
        <div>
            <FirstPage />
            <SecondPage />
            <ThirdPage />
            <FourthPage />
            <FifthPage />
        </div>
    );
};

export default HairStraightener;
