import { Image } from "antd";
import React from "react";
import logo from '../Assets/logo2.png';
import styles from '../Navbar/Styles/navbar.module.css';

const Navbar = () => {
  return (
    <div style={{ height: "85px", background: "rgb(3 115 153)" }}>
      <Image preview={false} height={"100%"} className={styles.navbar} src={logo} />
    </div>
  );
};

export default Navbar;
