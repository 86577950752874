import React from 'react'

const TermsAndConditions = () => {
  return (
    <div style={{paddingLeft:"1em", paddingRight:"1em"}}>
      <center>Lawsuit-Winning Terms &amp; Conditions</center>
      <br />
      <br />
      <span id="date">Updated and effective October 6, 2020</span>
      <br />
      <br />
      Thank you for visiting this website (the "Site"), a property of
      Lawsuit-Winning ("Company"). Company's owner, parents, subsidiaries, related
      companies, and each of their respective officers, directors, members, owners,
      employees, agents, representatives, and shareholders are included in any
      reference to "Company" herein. By using and/or accessing the Site, you agree
      to comply with and be bound by the following Terms and Conditions ("Terms and
      Conditions") (including the arbitration and class action waiver provisions
      below), the Privacy Policy ("Privacy Policy") and any and all other applicable
      Company operating rules, policies, price schedules and other supplemental
      terms and conditions or documents that may be published from time to time, are
      expressly incorporated herein by reference (collectively, the "Agreement").
      Please review the complete terms of the Agreement carefully. If you do not
      agree to the Agreement in its entirety, you are not authorized to use the Site
      in any manner or form.
      <br />
      <br />
      <span className="section_title">I. Requirements.</span> The Site is not
      intended for use by individuals under eighteen (18) years of age. If you are
      under eighteen (18) years of age, you do not have permission to use and/or
      access the Site. The Site, and the ability to apply for Services, are
      available only to individuals at least eighteen (18) years of age who can
      enter into legally binding contracts under applicable law, and whom are
      residents of the United States. <br />
      <br />
      <span className="section_title">II. Agreement Acceptance.</span> This
      Agreement constitutes the entire and only agreement between you and Company
      with respect to your use of this Site, and supersedes all prior agreements,
      representations, warranties and/or understandings with respect to the Site. As
      such, you agree to the terms and conditions set forth in this Agreement with
      respect to your use of the Site. We may amend the Agreement from time to time
      in our sole discretion, without specific notice to you; provided, however,
      that any amendment or modification to the arbitration provisions, prohibition
      on class actions provisions or any other provisions applicable to dispute
      resolution shall not apply to any disputes incurred prior to the applicable
      amendment or modification. The latest Agreement will be posted on the Site,
      and you should review the Agreement prior to using the Site. By your continued
      use of the Site, you hereby agree to comply with and be bound by all of the
      terms and conditions contained within the Agreement effective at that time
      (other than with respect to disputes arising prior to the amendment or
      modification of the dispute resolution provisions, which shall be governed by
      the dispute resolution provisions in effect at the time of the subject
      dispute). <br />
      <br />
      <span className="section_title">III. Description of the Site.</span> The Site
      provides users with an opportunity to apply for products and/or services
      ("Services"), as offered by Company's third party service providers (the
      "Third Party Service Providers"). Please be advised that Company does not
      itself provide services, and the ultimate terms and conditions of any product
      or service provided by its Third Party Service Providers will be determined by
      those Third Party Service Providers. To qualify to receive Services, you must
      first fully complete the application form located at the Site, as well as the
      application form set forth on the applicable Third Party Service Provider's
      Site. The information that you supply on the Site includes, but is not limited
      to: (a) your first name; (b) your last name; (c) your email address; (d) your
      telephone number; and (e) any other information requested in a web form
      contained on the Site (collectively, the "Site Registration Data"). Company's
      use of the Site Registration Data shall be governed by the Privacy Policy. To
      access the Privacy Policy please
      <a href="http://lawsuit-winning.com/privacypolicy.php">click here</a>. Upon
      entering your Site Registration Data and clicking on the applicable submission
      button on the Site, you may be redirected to the Site of a Third Party Service
      Provider. Company may also transfer your Site Registration Data to its Third
      Party Service Providers in connection with facilitating the processing of your
      Services application. The information that you must supply on the Third Party
      Service Provider's Application shall be determined by the applicable Third
      Party Service Provider (collectively, the "TP Registration Data" and, together
      with the Site Registration Data, the "Registration Data"). Any information
      that you supply to any Third Party Service Provider shall be governed by that
      Third Party Service Provider's privacy policy. Application approval, and the
      ultimate terms and conditions of any product or service provided by any Third
      Party Service Provider(s), will be determined by those Third Party Service
      Providers. Unless explicitly stated otherwise, any future offers made
      available to you on the Site that augments or otherwise enhances the current
      features of the Site shall be subject to the Agreement. You understand and
      agree that Company is not responsible or liable in any manner whatsoever for
      your use of, or inability to use and/or qualify for, Services or other Third
      Party Service Provider sponsored products and/or services, or for any dispute
      between you and any Third Party Service Providers. You understand and agree
      that Company shall not be liable to you or any third party for any
      modification, suspension or discontinuation of any product, service or
      promotion offered by any Third Party Service Providers. If Company terminates
      the Agreement for any reason, Company shall have no liability or
      responsibility to you. You understand and agree that refusal to use the Site
      is your sole right and remedy with respect to any dispute with Company, and
      the Agreement only governs your use of the Site.
      <br />
      <br />
      <span className="section_title">IV. Privacy Policy.</span> We reserve the
      right to use all information regarding your use of the Site, and any and all
      personal information, in accordance with the terms of our Privacy Policy. Use
      of the Site, and all comments, feedback, information, Site Registration Data
      or materials that you submit through or in association with the Site, are
      subject to our Privacy Policy.
      <br />
      <br />
      <span className="section_title">V. Proprietary Rights.</span> The content,
      organization, graphics, design, compilation, magnetic translation, digital
      conversion, software, services and other matters related to the Site are
      protected under applicable copyrights, trademarks and other proprietary
      (including intellectual property) rights. The copying, redistribution,
      publication or sale by you of any part of the Site is strictly prohibited.
      Retrieval of material from the Site by automated means or any other form of
      scraping or data extraction in order to create or compile, directly or
      indirectly, a collection, compilation, database or directory without written
      permission from Company is prohibited. You do not acquire ownership rights to
      any content, document, software, services or other materials viewed at or
      through the Site. The posting of information or material on the Site by
      Company does not constitute a waiver of any right in such information and/or
      materials. The Site name and logo, and all associated graphics, icons and
      service names, are trademarks of Company. All other trademarks are the
      property of their respective owners. The use of any trademark without the
      applicable trademark owner's express written consent is strictly prohibited.
      <br />
      <br />
      <span className="section_title">VI. License Grant.</span> You are granted a
      non-exclusive, non-transferable, revocable and limited license to access and
      use the Site and associated content in accordance with the Agreement. Company
      may terminate this license at any time for any reason. You may use the Site on
      one computer for your own personal, non-commercial use. No part of the Site
      may be reproduced in any form or incorporated into any information retrieval
      system, electronic or mechanical. You may not use, copy, emulate, clone, rent,
      lease, sell, modify, decompile, disassemble, reverse engineer or transfer the
      Site or any portion thereof. Company reserves any rights not explicitly
      granted in the Agreement. You may not use any device, software or routine to
      interfere or attempt to interfere with the proper working of the Site. You may
      not take any action that imposes an unreasonable or disproportionately large
      load on Company infrastructure. Your right to use the Site is not
      transferable.
      <br />
      <br />
      <span className="section_title">VII. Legal Warning.</span> Any attempt by any
      individual to damage, destroy, tamper with, vandalize and/or otherwise
      interfere with the operation of the Site, is a violation of criminal and civil
      law. Company will pursue any and all remedies in this regard against any
      offending individual or entity to the fullest extent permissible by law.
      <br />
      <br />
      <span className="section_title">VIII. Modification.</span> Company reserves
      the right to edit, modify, or delete any documents, information or other
      content appearing on the Site in our sole discretion.
      <br />
      <br />
      <span className="section_title">IX. Indemnification.</span> You agree to
      indemnify and hold Company, its parents, subsidiaries and related companies,
      and each of their respective members, officers, directors, owners, employees,
      agents, co-branders and/or other partners, harmless from and against any and
      all claims, expenses (including reasonable attorney's fees), damages, suits,
      costs, demands and/or judgments whatsoever, made by any third party due to or
      arising out of: (a) your use of the Site and/or Services; (b) your breach of
      the Agreement; (c) any dispute between you and any Third Party Service
      Providers; and/or (d) your violation of any rights of another individual
      and/or entity. The provisions of this Section IX are for the benefit of
      Company, its parents, subsidiaries and/or related companies, and each of their
      respective officers, directors, members, owners, employees, agents,
      shareholders, licensors, suppliers and/or attorneys. Each of these individuals
      and entities shall have the right to assert and enforce these provisions
      directly against you on its own behalf.
      <br />
      <br />
      <span className="section_title">X. Disclaimer of Warranties.</span> The Site,
      Services and/or any other products and/or services that you may apply for
      through the Site are provided to you on an "as is" and "as available" basis
      and all warranties, express and implied, are disclaimed to the fullest extent
      permissible pursuant to applicable law (including, but not limited to, the
      disclaimer of any warranties of merchantability, non-infringement of
      intellectual property and/or fitness for a particular purpose). In particular,
      but not as a limitation thereof, Company makes no warranty that: (a) the Site,
      Services and/or any other products and/or services that you may apply for
      through the Site will meet your requirements; (b) the Site, Services and/or
      any other products and/or services that you may apply for through the Site
      will be uninterrupted, timely, secure or error-free; (c) you will qualify for
      Services from our Third Party Service Providers; or (d) the results that may
      be obtained from the use of the Site, Services and/or any other products
      and/or services that you may apply for through the Site will be accurate or
      reliable. The Site, Services and/or any other products and/or services that
      you may apply for through the Site may contain bugs, errors, problems or other
      limitations. We will not be liable for the availability of the underlying
      internet connection associated with the Site. No advice or information,
      whether oral or written, obtained by you from Company, its Third Party Service
      Providers or otherwise through or from the Site, shall create any warranty not
      expressly stated in the Agreement.
      <br />
      <br />
      <span className="section_title">XI. Third Party Websites.</span> The Site may
      provide link out or refer you to third party websites. Company has no control
      over such third party websites, and therefore you hereby acknowledge and agree
      that Company is not responsible for the availability of such third party
      websites and/or resources, including but not limited to those of its Third
      Party Service Providers. Company does not endorse, and is not responsible or
      liable for, any terms and conditions, privacy policies, content, advertising,
      services, products and/or other materials at or available from such third
      party websites or resources, or for any damages and/or losses arising
      therefrom.
      <br />
      <br />
      <span className="section_title">XII. Limitation of Liability.</span> You
      expressly understand and agree that Company shall not be liable to you or any
      third party for any direct, indirect, incidental, special, consequential
      and/or exemplary damages including, but not limited to, damages for loss of
      profits, goodwill, use, data or other intangible losses (even if Company has
      been advised of the possibility of such damages), to the fullest extent
      permissible by law for: (a) the use or the inability to use the Site, Services
      and/or any other products and/or services that you may apply for through the
      Site; (b) the cost of procurement of substitute goods and services resulting
      from any goods, data, information and/or Services purchased or obtained from,
      or transactions entered into through the Site; (c) the failure to qualify for
      Services from our Third Party Service Providers; (d) the unauthorized access
      to, or alteration of, your Registration Data; and (e) any other matter
      relating to the Site, Services and/or any other products and/or services that
      you may apply for through the Site. This limitation applies to all causes of
      action, in the aggregate including, but not limited to, breach of contract,
      breach of warranty, negligence, strict liability, misrepresentations and any
      and all other torts. You hereby release Company and its Third Party Service
      Providers from any and all obligations, liabilities and claims in excess of
      the limitations stated herein. If applicable law does not permit such
      limitations, the maximum liability of Company to you under any and all
      circumstances will be three hundred dollars ($300.00). The negation of damages
      set forth above is a fundamental element of the basis of the bargain between
      you and Company. The Site, Services and/or any other products and/or services
      that you may apply for through the Site would not be provided to you without
      such limitations.
      <br />
      <br />
      <span className="section_title">XIII. E-Sign Consent.</span> <br />
      <br />
      By clicking the "submit" button on the Site, you adopt such as your electronic
      signature, and consent and agree that: Company may provide you with electronic
      communications and disclosures (collectively, "Communications") via email, by
      making them accessible on this Site or other website designated by Company;
      and your electronic signature on agreements and documents has the same effect
      as if you signed them in writing. <br />
      <br />
      You may withdraw your consent to receive electronic Communications at any time
      by contacting us at info@lawsuit-winning.com. Withdrawal of your consent to
      receive electronic Communications may result in termination of your access to
      the Site and/or Services. Any withdrawal be effective only after we have a
      reasonable period of time to process your withdrawal.
      <br />
      <br />
      You are responsible for providing us with true and accurate information,
      including contact information, and to maintain and update any such contact
      information.
      <br />
      <br />
      To access and retain the electronic Communications, you will need the
      following: access/use of a computer or mobile device with internet or mobile
      connectivity and an operating system capable of receiving, accessing, and
      displaying Communications; an internet browser that we support; sufficient
      storage space to save Communications and/or a printer to print them; and a
      valid email account and software to access such email account. You have the
      right to receive Communications in paper form. Please contact us at
      info@lawsuit-winning.com to request a paper copy of any Communications at no
      charge. A request will not be treated as withdrawal of consent to receive
      electronic Communications. <br />
      <br />
      <span className="section_title">XIV. Dispute Resolution Provisions.</span> The
      Agreement shall be treated as though it were executed and performed in Los
      Angeles, California and shall be governed by and construed in accordance with
      the laws of the State of California (without regard to conflict of law
      principles). In the event of any dispute arising under or related to the Site,
      Services, terms and conditions of the Agreement, the breach of same by any
      party hereto, or the arbitrability of any claim (including any questions about
      the scope, applicability, interpretation, validity, or enforceability of this
      section or the Agreement): (a) the parties agree to exclusively submit their
      dispute for resolution by binding arbitration before the American Arbitration
      Association in Los Angeles, California, in accordance with the then current
      Commercial Arbitration rules of the American Arbitration Association ("AAA").
      Any such disputes, including any threshold disagreement about the
      arbitrability of any claim, shall be delegated to the arbitrator (and not a
      court). The United States Federal Arbitration Act shall govern the
      interpretation of this arbitration clause. Arbitration hereunder shall be
      conducted by one neutral arbitrator appointed by the AAA. At the request of
      either party, the arbitrator will enter an appropriate protective order to
      maintain the confidentiality of information produced or exchanged in the
      course of the arbitration proceedings. In no event shall the arbitrator have
      any authority to award punitive or other damages not measured by the
      prevailing party's actual damages, except as may be required by statute.
      Irrespective of the outcome of arbitration, each party shall bear its own
      costs and expenses, including its own attorney's fees, and an equal share of
      the arbitrator's fees and administrative fees of arbitration. The arbitrator
      shall not determine or award any alternative allocation of costs and expenses,
      including any attorney's fees. Any award rendered shall be final and
      conclusive to the parties and a judgment thereon may be entered in any court
      of competent jurisdiction. Nothing contained herein shall be construed to
      preclude any party from seeking injunctive relief in order to protect its
      rights pending an outcome in arbitration. Regardless of any statute or law to
      the contrary, any claim or cause of action arising out of or related to your
      use of the Site, the Services, any products or services offered through the
      Site, and/or this Agreement must be brought within one (1) year after such
      claim or cause of action arose or be forever barred.
      <br />
      <br />
      To the extent permitted by law, you agree that you will not bring, join or
      participate in any class action lawsuit or class wide arbitration as to any
      claim, dispute or controversy that you may have against Company and/or its
      owners, employees, officers, directors, members, representatives and/or
      assigns. You agree to the entry of injunctive relief to stop such a lawsuit or
      to remove you as a participant in the suit. You agree to pay the attorney's
      fees and court costs that Company incurs in seeking such relief. This
      provision preventing you from bringing, joining or participating in class
      action lawsuits: (a) does not constitute a waiver of any of your rights or
      remedies to pursue a claim individually and not as a class action in binding
      arbitration as provided above; and (b) is an independent agreement.
      <br />
      <br />
      You may opt-out of these dispute resolution provisions by providing written
      notice of your decision within thirty (30) days of the date that you first
      access the Site. Thereafter, these dispute resolution provisions shall be
      final and binding.
      <br />
      <br />
      <span className="section_title">XV. Miscellaneous.</span> Should any part of
      the Agreement be held invalid or unenforceable, that portion shall be
      construed consistent with applicable law and the remaining portions shall
      remain in full force and effect. The Agreement is personal between you and
      Company and governs your use of the Site, superseding any and all prior and/or
      contemporaneous agreements between you and Company. To the extent that
      anything in or associated with the Site and/or any Company offering is in
      conflict or inconsistent with the Agreement, the Agreement shall take
      precedence. Our failure to enforce any provision of the Agreement shall not be
      deemed a waiver of such provision nor of the right to enforce such provision.
      The parties do not intend that any agency or partnership relationship be
      created through operation of the Agreement.
      <br />
      <br />
      <span className="section_title">XVI. Contact Us.</span> If you have any
      questions about the practices of the site or this Agreement, please e-mail us
      at info@lawsuit-winning.com, or send a letter to Lawsuit-Winning, 21781
      Ventura Blvd #10043, Woodland Hills, CA 91364, or call us at 516.362.3623.
      <br />
      <br />
      © 2024 lawsuit-winning.com. All rights reserved.
      <br />
      <deepl-input-controller />
    </div>
  )
}

export { TermsAndConditions };
