import React from 'react'

const CaliforniaPrivacy = () => {
    return (
        <div style={{ paddingLeft: "1em", paddingRight: "1em" }}>
            <center>
                <span className="section_title">California Consumer Privacy Act</span>
            </center>
            <br />
            <center>
                <span style={{ fontWeight: "bold" }}>California Residents</span>
            </center>
            We collect personal information about consumers in the following categories:
            identifiers; information that identifies, relates to, describes, or is capable
            of being associated with, a particular individual; internet or other
            electronic network activity information; and geolocation data. The personal
            information collected is used for the following business or commercial
            purposes: 1) to provide you with the ability to access and interact with the
            Site; 2) personalize and improve the Site and/or to contact you when necessary
            in connection with transactions entered into by you on the Site; 3) to provide
            you with information that you may request and fulfill requests submitted
            through the Site; 4) to track consumer interaction with the Site and with our
            email advertisements (counting ad impressions, verifying positioning and
            quality of ad impressions); 5) for advertising/marketing services, including
            affiliate marketing; 6) to detect security incidents, or protect against
            malicious, deceptive, fraudulent, or illegal activity; 7) to debug to identify
            and repair errors that affect functionality; 8) for short-term, transient use;
            9) for customer service; 10) for analytics, internal research and business
            purposes; 11) to verify and maintain quality or safety on the Site; and 12)
            for legal compliance. Additional information is contained in our Privacy
            Policy, which is located here:{" "}
            <a href="http://lawsuit-winning.com/privacypolicy.php">click here</a>
            <br />
            <br />
            If you are a California resident, under the California Consumer Privacy Act
            ("CCPA"), you have the right to opt-out of the sale of your personal
            information. To submit a request to opt-out of the sale of your personal
            information, complete the information requested in the form below and click on
            the "Do Not Sell My Personal Information" link
            <a href="https://ccpa-optout.lawsuit-winning.com">
                https://ccpa-optout.lawsuit-winning.com
            </a>
            , or email us at CCPA-optout@lawsuit-winning.com. To process your request to
            opt-out, we may require that you submit the following information in
            conjunction with your request: your name, address, email address, and phone
            number. <br />
            <br />
            You may exercise your right to opt-out through an authorized agent. If you
            elect to use an authorized agent to exercise your right to opt-out of the sale
            of your personal information, we may require the following before processing
            the request: 1) provide proof of your written and signed permission with the
            authorized agent to act on your behalf; 2) directly verify your own identity
            with us; and 3) directly confirm with us that you provided the authorized
            agent permission to submit the request on your behalf. The information which
            you submit in conjunction with your opt-out request will be used for the
            express purposes of verification and complying with your opt-out request.
            <br />
            <br />
            Individuals with a disability which interferes with their ability to submit an
            opt-out request hereunder may contact us by email at
            CCPA-optout@lawsuit-winning.com or by mail Lawsuit-Winning, 21781 Ventura Blvd
            #10043, Woodland Hills, CA 91364 to access this notice and information in an
            alternative format.
            <br />
            <br />
            <deepl-input-controller />
        </div>

    )
}

export { CaliforniaPrivacy };
