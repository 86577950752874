import { Typography } from 'antd';
import React from 'react';
const { Text } = Typography;

const PrivacyPolicy = () => {
  return (
    <div style={{paddingLeft:"1em", paddingRight:"1em"}}>
      <center>Lawsuit-Winning Privacy Policy</center>
      <br />
      <span id="date">Updated and effective March 1, 2024</span>
      <br />
      <br />
      Thank you for visiting Lawsuit-Winning ("Company") located at
      www.lawsuit-winning.com (the "Site"). This Privacy Policy outlines the privacy
      practices associated with the Site, and you hereby agree to it in its
      entirety, including but not limited to: (i) access or use the Site; (ii) agree
      to receive e-mail from us via the Site, or any other means; (iii) register on
      the Site to receive related products and/or services from third party service
      providers; and/or (iv) obtain, or attempt to obtain, any products and/or
      services. Your use of the Site is conditioned upon your acceptance of the
      terms contained herein. If you do not agree to the terms contained herein,
      please do not access or use the Site.
      <br />
      <br />
      <span className="section_title">Collection of Personal Information</span>
      <br />
      <br />
      We collect personal information directly from you when you visit and interact
      with the Site, as we buy media to build consumer awareness, and when you when
      you open and/or click on email advertisements, as well as if you request to
      unsubscribe from receiving future email advertisements from us.
      <br />
      <br />
      The types of personal information that we collect automatically through the
      Site include: (i) your IP address; (ii) referring web page; (iii) your age
      range; (iv) cookies, beacons, pixel tags, and mobile ad identifiers; and (v)
      geolocation data. Our servers automatically recognize your IP address each
      time you visit the Site and the web page from which you came. Your IP address
      is used to help identify you and to gather broad demographic information about
      you to and to better tailor our product and service offerings contained within
      the Site. Your IP address can also be used to help diagnose problems with our
      servers, and to administer the Site. We also collect additional personal
      information which you submit to us through the Site including: (i) your first
      name; (ii) your last name; (iii) your email address; (iv) your telephone
      number; and (v) any other information requested in a web form contained on the
      Site.
      <br />
      <br />
      The first time that a user provides an e-mail address in connection with their
      activities at the Site, we assign an identification number to that e-mail
      address and deploy a cookie to the applicable user's PC. A cookie is a small
      data text file, which is stored on your computer's hard drive (if your web
      browser permits) that can later be retrieved to identify you to us. Cookies
      make your use of the Site easier, make the Site run more smoothly, and help us
      to maintain a secure Site. The cookies may collect information about you, such
      as the referring website address, your IP address, your browser type and
      version, your Internet service provider, the area code and zip code associated
      with your server, the time and date of your visit, and the time spent on the
      Site. Whenever a user comes back to the Site using the same PC, the cookie
      allows the Site to identify the user and to recall the users e-mail address.
      If, at any time, a user provides other information in connection with their
      activities on the Site (such as name, address, etc.), we may store that
      information, along with a user's e-mail address, in our database. We may use
      the information stored in our database: (i) to effectuate the purpose or
      transaction for which the information was originally provided by a user; (ii)
      to ensure that a user will not be repeatedly exposed to the same
      advertisements, offers and/or promotions while visiting the Site; (iii) to
      pre-populate information fields in the event that user wishes to purchase
      products and/or services or sign up for and/or subscribe to services,
      promotions or other offers in the future; (iv) to, in connection with regular
      communication with a user, include offers, promotions or advertisements that
      were historically, or are likely to be, of interest to that user; or to track
      your use of the Site, and to customize the content that you might see on the
      Site. To disable and reject cookies, adjust the settings via the options menu
      of your Internet browser. Many web browsers offer their users to opt to not be
      tracked, however unless otherwise required by law, we do not guarantee that we
      will respond to such Do Not Track signals. <br />
      <br />
      Other methods of tracking: An action tag or a web-beacon (also known as a
      clear gif/pixel tag) is a method used to track responses or actions by
      visitors who view certain advertisements and/or other information on the Site.
      Action tags are 1x1 pixel images embedded in a website page that are used to
      transparently collect information. We may use action tags to count the number
      of times that visitors click on a particular banner ad or visit the pages of
      the Site and to provide information about what products/services are viewed or
      purchased. We reserve the right to retain this cookie and action tag data
      indefinitely, and to share information generated by these cookies and action
      tags with our advertisers, affiliates and service providers.
      <br />
      <br />
      We also collect personal information through advertising networks, internet
      service providers (ISPs), operating systems, and social networks.
      <br />
      <br />
      <span className="section_title">Usage of Personal Information</span>
      <br />
      <br />
      The above personal information is used: 1) to provide you with the ability to
      access and interact with the Site; 2) personalize and improve the Site and/or
      to contact you when necessary in connection with transactions entered into by
      you on the Site; 3) to provide you with information that you may request and
      fulfill requests submitted through the Site; 4) to track consumer interaction
      with the Site and with our email advertisements (counting ad impressions,
      verifying positioning and quality of ad impressions); 5) for
      advertising/marketing services; 6) to detect security incidents, or protect
      against malicious, deceptive, fraudulent, or illegal activity; 7) to debug to
      identify and repair errors that affect functionality; 8) for short-term,
      transient use; 9) for customer service; 10) for analytics, internal research
      and business purposes; 11) to verify and maintain quality or safety on the
      Site; and 12) for legal compliance.
      <br />
      <br />
      We may also combine the information we have gathered about you with
      information from other sources. We may use third parties to help us operate
      our business and the Site or to administer activities on our behalf.
      <br />
      <br />
      When you submit your request for information through the Site by clicking on
      "the submit button", you hereby agree and acknowledge that your personal
      information will be placed into Company's database and that such personal
      information will be shared with third parties for marketing purposes. By
      submitting your personal information on the Site, you agree that we may share
      such personal information with our third party service providers in order to
      complete your request for the services featured on the Site. Our third party
      service providers may use the personal information that we transfer to them
      for any lawful purposes, subject to any restrictions contained herein
      including, without limitation, in connection with contacting you for purposes
      of completing your request for the services. To the extent that you submit any
      additional information on any site(s) hosted by a third party service
      provider, such information shall be governed by such third party service
      provider's privacy policy. Please be advised that Company does not itself
      provide products and/or services. The services will be provided by third party
      service providers, and the ultimate terms and conditions of the services will
      be determined by those third party service providers.
      <br />
      <br />
      Lawsuit-Winning provides its users with the opportunity to receive special
      products, offers and/or services from third parties including, but not limited
      to, our third party service providers. Where you indicate the desire to
      receive certain offers, products or services from a third party during the
      applicable information collection process, your personal information that was
      provided during the information collection process will be shared with the
      applicable third party so that such third party may provide their product or
      service to you and so that you do not have to complete another information
      collection form to purchase or receive that product or service.
      <br />
      <br />
      We may share the personal information that you supply to us with any third
      party and we may work with other businesses to bring selected retail
      opportunities to our users. These businesses and third parties may include,
      but are not limited to third party individuals, companies, and/or service
      providers we use to perform certain functions on our behalf. Examples of such
      functions include sending direct and electronic mail, removing duplicate
      information from user lists, analyzing data and providing marketing analysis.
      Such third party service providers performing these limited functions on our
      behalf shall have access to our users' personal information as needed to
      perform their functions for us, but we do not permit them to use your personal
      information for other purposes. Your email will be shared with our preferred
      mailing partner and other brands believed to be of interest to you for the
      purpose of sending third party promotional email communication. You may
      unsubscribe from any such emails at any time.
      <br />
      <br />
      We use demographic information to tailor the Site to the interests of our
      users. Demographic data may be shared with third parties including our third
      party advertisers so that they can tailor their advertisements to the
      appropriate audience. We reserve the right to provide aggregate or group data
      about our users for lawful purposes. Aggregate or group data is data that
      describes the demographics, usage or characteristics of our participants as a
      group, without disclosing personally identifiable information. By submitting
      your information through the Site, you agree to allow us to provide such
      aggregate data to third parties. By agreeing to the terms of this Privacy
      Policy, you hereby consent to the disclosure of any record or communication to
      any third party when we, in our sole discretion, determine the disclosure to
      be appropriate.
      <br />
      <br />
      We also reserve the right to release current or past user information in the
      event that we believe that the user is using, or has used, our products,
      services and/or Site: (i) in violation of the terms and conditions of this
      Privacy Policy or any other of our operating rules, policies, price schedules
      and other supplemental terms and conditions or documents that may be published
      by us from time to time; (ii) to commit unlawful acts; (iii) if such
      information is subpoenaed; provided, however, that, where permitted by
      applicable law, we shall provide you with e-mail notice, and opportunity to
      challenge the subpoena, prior to disclosure of any personal information
      pursuant to a subpoena; (iv) if we are sold or acquired; provided, however,
      that if we are involved in a merger, acquisition or sale of all or a portion
      of our assets, you will be notified via email and/or a prominent notice on the
      Site of any change in ownership or uses of your personal information, as well
      as any choices that you may have regarding your personal information; and/or
      (v) when we deem it necessary or appropriate including, without limitation,
      sharing your e-mail address with other third parties for suppression purposes
      in compliance with the CAN-SPAM Act of 2003, as amended from time to time, and
      Federal Trade Commission implementing regulations.
      <br />
      <br />
      By registering at the Site, you agree that such act constitutes an inquiry
      and/or application for purposes of the Amended Telemarketing Sales Rule (16
      CFR § 310 et seq.), as amended from time to time and applicable state
      do-not-call regulations. As such, notwithstanding that your telephone nuber
      may be listed on the Federal Trade Commissions Do-Not-Call List, and/or on
      applicable state do-not-call lists, we, as well as our third party service
      providers, retain the right to contact you via telemarketing in accordance
      with the Rule and applicable federal and state do-not-call regulations.
      <br />
      <br />
      In addition, you agree that by submitting your personal information through
      the Site, you agree to receive mobile marketing including, but not limited to,
      text-message based marketing, from us and our third party advertisers and
      marketers (where such marketing is permitted by applicable law). As such,
      notwithstanding that your mobile telephone number may be listed on state
      and/or federal Do-Not-Call registries, we retain the right to contact you via
      text-message based marketing in accordance with applicable state and federal
      law. Further, you agree that we reserve the right to share, sell, rent, lease
      and/or otherwise distribute your mobile telephone and other mobile data
      with/to any third-party for any and all non-marketing uses permitted by this
      Privacy Policy and applicable law.
      <br />
      <br />
      You may unsubscribe from any of our e-mail marketing programs at any time via
      an unsubscribe hyperlink that allows the recipient to unsubscribe from the
      specific marketing program by clicking the hyperlink provided and following
      the instructions that follow. We process all unsubscribe requests in a timely
      fashion, but cannot guarantee that you will never receive another mailing from
      us, as we may obtain your e-mail address again through a different e-mail
      marketing program that you have opted in to. If you receive another e-mail
      communication from us, you will be able to remove your e-mail address via the
      unsubscribe hyperlink. We are able to offer our free services, in part, based
      upon the willingness of our users to be contacted by our marketing partners,
      including affiliated third party advertisers. By allowing us to make the
      information that you supply available to our third party marketing partners,
      you will receive free product and service information that may be of interest
      to you. You may, at any time, notify us that you do not wish to have your
      personal information disclosed to third parties for offers and promotions by
      sending an e-mail to info@lawsuit-winning.com.
      <br />
      <br />
      <span className="section_title">TCPA Consent</span>
      <br />
      <br />
      This Site is compliant with the Telephone Consumer Protection Act as
      implemented by the Federal Communications Commission. By clicking the "submit"
      button on the Site, you agree by electronic signature to the Terms and
      Conditions and this Privacy Policy and authorize Company and its partners to
      contact you by email and at the phone number you entered using technology
      including recurring auto-dialers, pre-recorded messages, and text messages,
      even if your phone is a mobile number or is currently listed on any state,
      federal, or corporate "Do Not Call" list. You understand that your telephone
      company may impose charges on you for these contacts, and that you can revoke
      this consent at any time. For SMS campaigns Text STOP to cancel and HELP for
      help. Message and data rates may apply. You are not required to give your
      consent as a condition of service, and may call us 1-888-520-9962 to request
      your free investor guide. By clicking the "submit" button and submitting this
      form, I affirm that I have read and agree to this Site's Terms and Conditions
      (including the arbitration provision and the E-SIGN consent) and Privacy
      Policy.
      <br />
      <br />
      <span className="section_title">Security Precautions</span>
      <br />
      <br />
      The privacy of your personal information is very important to us. As such, we
      endeavor to safeguard our users' personal information. When users make
      personal information available to us, their personal information is protected
      both online and offline (to the extent that we maintain any personal
      information offline). Access to your personal information is strictly limited,
      and we take reasonable measures to ensure that your personal information is
      not accessible to the public. The servers that we store personal information
      on are kept in a secure physical environment. We also have security measures
      in place to protect the loss, misuse and alteration of personal information
      under our control. Please be advised, however, that while we take every
      reasonable precaution available to protect your data, no storage facility,
      technology, software, security protocols or data transmission over the
      Internet can be guaranteed to be 100% secure. Computer hackers that circumvent
      our security measures may gain access to certain portions of your personal
      information, and technological bugs, errors and glitches may cause inadvertent
      disclosures of your personal information; provided, however, that any attempt
      to breach the security of the network, our servers, databases or other
      hardware or software constitutes a crime punishable by law. For the reasons
      mentioned above, we cannot guarantee or warrant that your personal information
      will be absolutely secure. Any transmission of data at or through the Site is
      at your own risk.
      <br />
      <br />
      All of our users' personal information is restricted in our offices. Only
      employees or third party agents who need the personal information to perform a
      specific job function are granted access to personal information. Employees
      not adhering to our policies are subject to disciplinary action. In compliance
      with applicable federal and state laws, we shall notify you and any applicable
      regulatory agencies in the event that we learn of an information security
      breach with respect to your personal information. You will be notified via
      e-mail in the event of such a breach. Please be advised that notice may be
      delayed in order to address the needs of law enforcement, determine the scope
      of network damage, and to engage in remedial measures.
      <br />
      <br />
      <span className="section_title">Age Restrictions (Minors)</span>
      <br />
      <br />
      No information should be submitted to, or posted at, the Site by visitors
      under eighteen (18) years of age or the applicable age of majority (if greater
      than eighteen 18 years of age). Persons under eighteen (18) years of age or
      the applicable age of majority are not permitted to access the Site and we do
      not knowingly collect personal information from such individuals. We encourage
      parents and guardians to spend time with their children and to participate and
      monitor their online activities. To the extent that you believe that we have
      acquired the personal information of minors, please contact us immediately
      using the contact information below.
      <br />
      <br />
      <span className="section_title">Third Party Partner Websites</span>
      <br />
      <br />
      The Site may contain links to other third-party owned and/or operated websites
      including, but not limited to, the websites of our third party service
      providers. Company is not responsible for the privacy practices or the content
      of such websites. In some cases, you may be able to make a purchase through
      one of their third party websites. In these instances, you may be required to
      provide certain information, such as a credit card number, to register or
      complete a transaction at such website. These third party websites have
      separate privacy and data collection practices and Company has no
      responsibility or liability relating to them.
      <br />
      <br />
      <span className="section_title">Updating Your Information</span>
      <br />
      <br />
      At any time you may request Lawsuit-Winning to: (i) amend the personal
      information that we have on file for you and/or (ii) remove personal
      information that you have provided to us, or that we have collected. You may
      do so by contacting us via e-mail at info@lawsuit-winning.com; provided,
      however, that we ask individuals to identify themselves and the information
      requested to be accessed, amended or removed before processing such requests,
      and, to the extent permitted by applicable law, we may decline to process
      requests that are unreasonably repetitive or systematic, require
      disproportionate technical effort, jeopardize the privacy of others, or would
      be extremely impractical.
      <br />
      <br />
      Please be advised that deleting your personal information may terminate or
      limit your ability to apply for services through the Site. If you wish to
      apply for services without any hindrance or limitation, you may decide not to
      delete the personal information that we have on file for you. Note that after
      you delete your personal information, residual copies may take a period of
      time before they are deleted from our active servers and may remain in our
      backup systems.
      <br />
      <br />
      <span className="section_title">Option to Opt Out</span>
      <br />
      <br />
      We provide users with the option to opt-out from receiving information sent
      via electronic mailings on or own behalf of and that of our third party
      advertisers. Users may unsubscribe from receiving e-mail at any time by
      following the instructions contained at the end of every e-mailing.
      Unsubscribe requests can only be processed if you have registered with us in
      the first instance. If you have not registered with us and wish to opt out of
      receiving e-mail from a particular sender, please consult that sender's opt
      out policy, or contact that sender. Information sent on behalf of third party
      advertisers are prepared several days in advance, so users may continue to
      receive e-mail from us for up to ten (10) days following the unsubscribe
      procedures detailed above. If, after that point, a user is still receiving
      e-mail from us, the user should contact us at info@lawsuit-winning.com.
      Notwithstanding the foregoing, we may continue to contact you for purposes of
      communicating information relating to your application for the services, as
      well as to respond to any inquiry or request made by you. To opt-out of
      receiving services-related and/or inquiry response-related messages from us,
      you must cease applying for services, and cease submitting inquiries to us.
      <br />
      <br />
      <span className="section_title">Your California Privacy Rights </span>
      <br />
      <br />
      <u>
        This section is specific and applies only to web visitors who are California
        residents, under the meaning of Section 17014 of Title 18 of the Cal. Code
        of Regulations.
      </u>
      <br />
      <br />
      If you would like to learn how your personal information is shared with third
      parties (as defined in the Shine the Light Law, Cal. Civ. Code Sec. 1798.83),
      or what categories of personal information that we have shared with third
      parties in the preceding year, as well as the names and addresses of those
      third parties, and also if you would like to opt-out from the disclosure of
      your personal information to any third party for marketing purposes please
      contact us at: CCPA-optout@admediaryllc.com, or send postal mail to
      Lawsuit-Winning, 21781 Ventura Blvd #10043, Woodland Hills, CA 91364 or call
      us at 516.362.3623. Please be advised that where California residents opt-out
      from permitting their personal information to be shared, such individuals may
      still receive selected offers directly from Lawsuit-Winning, in accordance
      with applicable laws. <br />
      <br />
      Under the California Consumer Privacy Act of 2018 ("CCPA"), up to twice in a
      twelve (12) month period, a consumer has the right to request that a business
      disclose what personal information it collects, uses, discloses, and sells.
      Information regarding our collection, disclosure, and sale practices are
      detailed below, in addition to instructions on how to exercise your rights
      regarding your personal information.
      <br />
      <br />
      <i>What Personal Information Do We Collect?</i>
      <br />
      <br />
      We collect, and have collected personal information about our visitors in the
      preceding 12 months in the following categories:
      <br />
      <table>
        <thead>
          <tr>
            <th>Categories of Personal Information (PI)</th>
            <th>Specific Types</th>
            <th>
              Categories of Personal Information (PI) Specific Types Business or
              Commercial Purpose for Which Each Category Will be Used
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td scope="row">
              Identifiers (e.g., a real name, alias, postal address, unique personal
              identifier, online identifier, Internet Protocol address, email
              address, account name, social security number, driver's license
              number, passport number, or other similar identifiers)
            </td>
            <td>
              First name;
              <br />
              Last name;
              <br />
              Email address;
              <br />
              IP address;
            </td>
            <td>
              Site access and interaction;
              <br />
              Fulfillment of requests submitted through the Site;
              <br />
              Personalize and improve the Site;
              <br />
              Marketing;
              <br />
              Internal database;
              <br />
              Analyze the Site;
              <br />
              Secure the Site;
              <br />
              Customer service;
              <br />
              and Legal compliance
            </td>
          </tr>
          <tr>
            <td scope="row">
              Information that identifies, relates to, describes, or is capable of
              being associated with, a particular individual, including, but not
              limited to, his or her name, signature, social security number,
              physical characteristics or description, address, telephone number,
              passport number, driver's license or state identification card number,
              insurance policy number, education, employment, employment history,
              bank account number, credit card number, debit card number, or any
              other financial information, medical information, or health insurance
              information.
            </td>
            <td>
              Age range;
              <br />
              Phone number
            </td>
            <td>
              Site access and interaction;
              <br />
              Fulfillment of requests submitted through the Site;
              <br />
              Marketing;
              <br />
              Internal database;
              <br />
              Internal analysis;
              <br />
              Customer service;
              <br />
              and Legal compliance
            </td>
          </tr>
          <tr>
            <td scope="row">
              Internet or other electronic network activity information (e.g.,
              browsing history, search history, and information regarding a
              consumer's interaction with an Internet Web site, application, or
              advertisement)
            </td>
            <td>
              Time and date of your visit to the Site;
              <br />
              Time spent on the Site;
              <br />
              Referring web page;
              <br />
              Specific webpages you visited, including any third party websites
              reached through the Site;
              <br />
              Internet service provider;
              <br />
              Domain name servers;
              <br />
              Types of computers or devices accessing the Site;
              <br />
              Web browser type and version;
              <br />
              and Behavioral data (opens, clicks)
            </td>
            <td>
              Site access and interaction;
              <br />
              Fulfillment of requests submitted through the Site;
              <br />
              Personalize and improve the Site;
              <br />
              Marketing;
              <br />
              Internal database;
              <br />
              Analyze the Site;
              <br />
              Secure the Site;
              <br />
              Customer service;
              <br />
              and Legal compliance
            </td>
          </tr>
          <tr>
            <td scope="row">Geolocation data</td>
            <td>
              Geographic region based on IP address;
              <br />
              Area code and zip code associated with your web server
            </td>
            <td>
              Site access and interaction;
              <br />
              Fulfillment of requests submitted through the Site;
              <br />
              Personalize and improve the Site;
              <br />
              Marketing;
              <br />
              Internal database;
              <br />
              Analyze the Site;
              <br />
              Secure the Site;
              <br />
              Customer service;
              <br />
              and Legal compliance
            </td>
          </tr>
        </tbody>
      </table>
      <br />
      <i>What Personal Information Do We Disclose or Sell?</i>
      <br />
      <br />
      We have disclosed personal information for a business purpose, or sold
      personal information to third parties in the preceding 12 months in the
      following categories:
      <br />
      <table>
        <thead>
          <tr>
            <th>Categories of Personal Information (PI)</th>
            <th>
              Categories of Third Parties to Whom Such Information Was Disclosed or
              Sold
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              Identifiers (e.g., a real name, alias, postal address, unique personal
              identifier, online identifier, Internet Protocol address, email
              address, account name, social security number, driver's license
              number, passport number, or other similar identifiers)
            </td>
            <td>
              Direct lead buyers, including investment companies;
              <br />
              and marketing partners
            </td>
          </tr>
          <tr>
            <td>
              Information that identifies, relates to, describes, or is capable of
              being associated with, a particular individual, including, but not
              limited to, his or her name, signature, social security number,
              physical characteristics or description, address, telephone number,
              passport number, driver's license or state identification card number,
              insurance policy number, education, employment, employment history,
              bank account number, credit card number, debit card number, or any
              other financial information, medical information, or health insurance
              information.
            </td>
            <td>
              Direct lead buyers, including investment companies;
              <br />
              and marketing partners
            </td>
          </tr>
        </tbody>
      </table>
      <br />
      A comprehensive list of the third parties which we disclosure personal
      information can be found at: https://lawsuit-winning.com/partners/
      <br />
      <br />
      In addition to the above, to the extent legally required to comply with
      applicable laws and regulations, or to respond to a lawful request, or to
      otherwise protect our rights, we may also disclose any of the above personal
      information to the requesting governmental agency, entity, or person. As we
      continue to develop our business, we might sell or buy assets. In such
      transactions, personal information collected through the Site may be one of
      the transferred business assets. Also, in the event that we, or substantially
      all of our assets, are acquired, such personal information will be included
      within the transferred assets.
      <br />
      <br />
      We do not have any actual knowledge of the sale of personal information of
      minors under 16 years of age. There are no financial incentives to disclose.
      <br />
      <br />
      <i>
        Your Right to Request to Know and How to Submit a Verified Request to Know
      </i>
      <br />
      <br />
      A "request to know" is a request related to the personal information that a
      business has about such consumer. It may include a request for any or all of
      the following from us: 1) specific pieces of personal information that we have
      collected about you; 2) categories of personal information that we have
      collected about you; 3) categories of sources from which the personal
      information was collected; 4) categories of personal information that we have
      sold or disclosed for a business purpose about you; 5) categories of third
      parties to whom the personal information was sold or disclosed; and 6) the
      business or commercial purpose for collecting or selling personal information.
      <br />
      <br />
      The CCPA permits requests to know from the same consumer up to twice in a
      twelve (12) month period. <br />
      <br />
      To submit a request to know, please email your request to
      CCPA-optout@admediaryllc.com. To process your request, you may be required to
      submit your name, address, email address, and phone number in conjunction with
      your request. The name, address, email address and phone number which you
      provide will be used to verify your request and to attempt to identify you
      within our data records. If you elect to use an authorized agent to exercise
      your right to submit a request for information, we may require the following
      before processing the request: 1) provide proof of your written and signed
      permission with the authorized agent to act on your behalf; 2) directly verify
      your own identity with us; and 3) directly confirm with us that you provided
      the authorized agent permission to submit the request on your behalf. The
      information which you submit in conjunction with your request to know will be
      used for the express purposes of verification and providing you with
      responsive information to your request, if required.
      <br />
      <br />
      <i>
        Your Right to Request Deletion and How to Submit a Verified Request to
        Delete
      </i>
      <br />
      <br />
      You have the right to request the deletion of any personal information which
      we have collected about you, or which we maintain. To submit a verifiable
      request to delete, please click here:{" "}
      <a href="https://ccpa-optout.admediary.com">
        https://ccpa-optout.admediary.com
      </a>
      , or email your request to CCPA-optout@admediaryllc.com. To process your
      request, you may be required to submit your name, address, email address, and
      phone number in conjunction with your request. The name, address, email
      address and phone number which you provide will be used to verify your request
      and to attempt to identify you within our data records. If you elect to use an
      authorized agent to exercise your right to submit a request for deletion, we
      may require the following before processing the request: 1) provide proof of
      your written and signed permission with the authorized agent to act on your
      behalf; 2) directly verify your own identity with us; and 3) directly confirm
      with us that you provided the authorized agent permission to submit the
      request on your behalf. The information which you submit in conjunction with
      your request to delete will be used for the express purposes of verification
      and fulfilling your request, if required.
      <br />
      <br />
      To the extent that your personal information is necessary for us to maintain
      for a reason delineated in Cal. Civ. Code 1798.105(d), including but not
      limited to complete a transaction for which the personal information was
      collected, provide a good or service you have requested, detect security
      incidents, protect against malicious, deceptive, fraudulent, or illegal
      activity, debug, etc., or to comply with a legal obligation, we are not
      required to comply with your request for deletion. For instance, we are not
      required to delete personal information which is necessary to maintain
      suppression files for those individuals who have opted out of receiving email
      marketing from us. If we are unable to verify your request, we will notify you
      as well.
      <br />
      <br />
      <i>Your Right to Opt-Out of the Sale of Your Personal Information</i>
      <br />
      California affords consumers with the right to opt-out of the sale of their
      personal information. To submit a request to opt-out of the sale of your
      personal information, you may complete the information requested in our
      webform
      <a href="https://ccpa-optout.admediary.com">
        https://ccpa-optout.admediary.com
      </a>
      , or you may email us at CCPA-optout@admediaryllc.com or call us at
      516.362.3623. <br />
      <br />
      To process your request, to opt-out, you may be required to submit your email
      address and phone number in conjunction with your request. The email address
      and phone number which you provide will be used to verify your request and to
      attempt to identify you within our data records. You may exercise your right
      to opt-out through an authorized agent. If you elect to use an authorized
      agent to exercise your right to opt-out of the sale of your personal
      information, we may require the following before processing the request: 1)
      provide proof of your written and signed permission with the authorized agent
      to act on your behalf; 2) directly verify your own identity with us; and 3)
      directly confirm with us that you provided the authorized agent permission to
      submit the request on your behalf. The information which you submit in
      conjunction with your opt-out request will be used for the express purposes of
      verification and complying with your opt-out request.
      <br />
      <br />
      <i>
        Your Right to Non-Discrimination for the Exercise of Your Privacy Rights
      </i>
      <br />
      <br />
      California affords consumers with the right not to receive discriminatory
      treatment by a business for the exercise of their privacy rights conferred by
      the CCPA. This means that you cannot be denied goods or services; be charged
      different prices or rates; be provided with a different level or quality of
      goods or services; or have it suggested that you will receive a different
      price or rate or different level or quality of goods or services. However, a
      business may offer a financial incentive or price or service difference if it
      is reasonably related to the value of the consumer's data. A business shall
      notify consumers of any financial incentive or price or service difference.
      <br />
      <br />
      <span className="section_title">Amendments</span>
      <br />
      <br />
      We may revise this Privacy Policy from time to time. If we make any material
      changes to this Privacy Policy, we will notify of such by posting the updated
      Privacy Policy, which shall include an "Updated" and the applicable date to
      indicate when the changes become effective.
      <br />
      <br />
      <span className="section_title">Unauthorized Changes Liability</span>
      <br />
      <br />
      In no event shall we be liable for any damages of any kind arising in
      connection with: (i) your use of the Site; (ii) the unauthorized modification
      of your personal information; or (iii) advertisements provided by us, our
      marketing partners or any other third parties.
      <br />
      <br />
      <span className="section_title">
        Your Acceptance of this Privacy Policy/Modifications
      </span>
      <br />
      <br />
      By using the Site including, without limitation, applying for services and/or
      continuing to receive information from us, you agree to the terms of our
      Privacy Policy. We reserve the right, at our discretion, to change, modify,
      add and/or remove portions of this Privacy Policy at any time. If the manner
      in which we use personal information changes, we will notify users by: (i)
      sending the modified Privacy Policy to our users via email; and/or (ii) by any
      other reasonable means acceptable under applicable state and federal law. You
      will have a choice as to whether or not we use your information in this
      different manner, and we will only use your information in this different
      manner where you opt-in to such use.
      <br />
      <br />
      <span className="section_title">Contact Us</span>
      <br />
      <br />
      If you have any questions about this Privacy Policy or the Site itself, please
      email us at info@lawsuit-winning.com, or send postal mail to Lawsuit-Winning,
      21781 Ventura Blvd #10043, Woodland Hills, CA 91364, or call us at
      516.362.3623.
      <br />
      <br />
      © 2024 Lawsuit-Winning All rights reserved.
      <br />
      <deepl-input-controller />
    </div>

  )
}

export { PrivacyPolicy };
