import React from 'react'
import HairStraightener from '../HairStraightener/HairStraightener'
import Navbar from '../Navbar/Navbar'
import { Layout } from 'antd'
import { Route, Routes } from 'react-router-dom';
import { FooterComponent } from '../Footer';
import { CaliforniaPrivacy, PrivacyPolicy, TermsAndConditions } from '../Static';
const { Footer, Content } = Layout;

const App = () => {
  return (
    <>
      <Layout style={{ overflow: "hidden" }}>
        <Navbar />
        <Content style={{ background: 'white' }}>
          <Routes>
            <Route exact path="/" element={<HairStraightener />} />
            <Route exact path="/privacyPolicy" element={<PrivacyPolicy />} />
            <Route exact path="/termsAndConditions" element={<TermsAndConditions />} />
            <Route exact path="/californiaPrivacy" element={<CaliforniaPrivacy />} />
          </Routes>
        </Content>
        <Footer>
          <FooterComponent />
        </Footer>
      </Layout>
    </>
  )
}

export { App }
