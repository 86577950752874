import {
  applyMiddleware,
  legacy_createStore as createStore,
  combineReducers,
} from "redux";
import { thunk } from "redux-thunk";
import { hairSTraightenerReducer } from "./HairStraightener/state/reducer";
import { loadingReducer } from "./state/reducer";

const reducers = {
  hairSTraightenerReducer,
  loadingReducer
};

const rootReducers = combineReducers(reducers);
const store = applyMiddleware(thunk)(createStore)(rootReducers);

export { store, reducers };
