import { hairSTraightenerConstants } from "./action-types";

const initialState = {
  addDetails: false,
  addDetailsErr: false,
};

const hairSTraightenerReducer = (state = initialState, action) => {
  switch (action.type) {
    case hairSTraightenerConstants.ADD_DETAILS_SUCCESS:
      return {
        ...state,
        addDetails: action.data,
        addDetailsErr: false,
      };
    case hairSTraightenerConstants.ADD_DETAILS_ERROR:
      return {
        ...state,
        addDetails: false,
        addDetailsErr: action.err,
      };
      case hairSTraightenerConstants.CLEAR_DETAILS:
        return {
          ...state,
          addDetails: false,
          addDetailsErr: false,
        };
    default:
      return state;
  }
};
export { hairSTraightenerReducer };
