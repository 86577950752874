import { Col, Row, Typography } from "antd";
import React from "react";
const { Text, Title } = Typography;

const ThirdPage = () => {
    return (
        <div style={{ textAlign: "center", marginTop:'5rem', marginBottom:"5rem"}}>
            <Row justify={"center"} style={{ background: "#0492c2" }}>
                <Col xs={22} sm={20} md={18} lg={16} style={{ paddingBottom: "2em" }}>
                    <Title style={{ color: 'white' }}>ABOUT HAIR STRAIGHTENER AND RELAXER LAWSUIT</Title>
                    <Text style={{ color: 'white', display: "block", fontSize: '1.2em' }}>
                        In today’s world, there is a growing demand for cosmetics and measures
                        to look good. The global cosmetic market size was valued at $380.2
                        billion in 2019 and is projected to reach $463.5 billion by 2027.
                        Products in the market should comply with industry standards and should
                        all pass a quality test before reaching the consumer. Manufacturers do
                        not always take necessary precautions to protect the consumer, which can
                        lead to hazardous products that ultimately affect an individual’s
                        health. Hair straightener products contain chemicals that may increase
                        cancer risk rates. Several chemicals such as parabens, bisphenol A,
                        metals, and formaldehyde were found in many hair straightener products.
                        These chemicals are also known to be carcinogens that can potentially
                        increase the risk of developing other cancers. A study conducted by the
                        NIH and NIEHS (National Institute of Environmental Health Sciences)
                        studied a subject group of 33,947 women over a period of ~ 10 years. It
                        was concluded at the end of the study that people that were using hair
                        straightening products for the last 12 months had a twofold increased
                        risk of developing uterus cancer. Of the participants who reported using
                        hair straightening products, 60% self-identified as Black women. The
                        health adverse effects may be greater for Black women due to the higher
                        prevalence of use.
                    </Text>
                </Col>
            </Row>
        </div>
    );
};

export default ThirdPage;
