import { startLoading, stopLoading } from "../../state/actions";
import { hairSTraightenerApi } from "../utils/api";
import { hairSTraightenerConstants } from "./action-types";

const addDetailsErr = (err) => {
  return {
    type: hairSTraightenerConstants.ADD_DETAILS_ERROR,
    err: err,
  };
};

const addDetailsSuccess = (data) => {
  return {
    type: hairSTraightenerConstants.ADD_DETAILS_SUCCESS,
    data: data,
  };
};

const addDetailsEvent = (data) => {
  return (dispatch) => {
    dispatch(startLoading());
    hairSTraightenerApi
      .addDetails(data)
      .then((response) => {
        dispatch(addDetailsSuccess(response.data.info));
        dispatch(stopLoading());
      })
      .catch((err) => {
        dispatch(addDetailsErr(err));
        dispatch(stopLoading());
      });
  };
};

const clearDetails = () =>{
  return {
    type: hairSTraightenerConstants.CLEAR_DETAILS,
  };
}

export const hairSTraightenerAction = {
  addDetailsEvent,
  clearDetails
};
