import { Col, Row, Typography } from "antd";
import React from "react";
import { Link } from "react-router-dom";
const { Text } = Typography;

const FooterComponent = () => {
  return (
    <Row justify={"center"}>
      <Col xs={22} sm={20} md={16} lg={18}>
        <Text style={{ fontSize: "0.8em" }}>
          Copyright © 2024, Lawsuit-Winning <Link to={"/privacyPolicy"}>Privacy Policy</Link> |{" "}
          <Link to={"/termsAndConditions"}>Terms & Conditions</Link> |{" "}
          {/* <Link to={'/donotSell'}>CA Residents Only - Do Not Sell My Info</Link> |{" "} */}
          <Link to={'/californiaPrivacy'}>California Privacy</Link>
          {/* <Link to={'unsubscribe'}>Unsubscribe</Link> */}
        </Text>
        <Text style={{ fontSize: "0.8em", marginTop: "1.2em", display: "block" }}>
          Lawsuit-Winning is devoted to helping consumers who may have been
          injured by prescription medications, financial fraud, automobile
          recalls, and various other issues where they could seek legal
          representation. THIS IS A PAID LEGAL ADVERTISEMENT. This is not a law
          firm or referral service and does not provide legal advice. This is a
          free connection service only and there is no charge to be connected with
          an attorney. Claim reviews will be performed by a third party attorney
          and the attorney who receives your information may not offer a free
          claim evaluation. This is not a substitute for medical advice from your
          doctor, so never start or stop taking medication without consulting a
          doctor first. We do not recommend or endorse any attorneys that pay to
          participate in this advertisement. An attorney-client relationship is
          not formed when you submit the form and you are under no obligation to
          retain a lawyer who contacts you through this service. Services are not
          available in all states. This website is not affiliated in any way with
          any pharmaceutical company, drug producer, or attorney, and makes no
          claim to any registered trademarks or brands of any company. We help you
          find information to fight for your rights!
        </Text>
      </Col>
    </Row>
  );
};

export { FooterComponent };
