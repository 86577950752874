import axios from "axios";

const apiURL = process.env.REACT_APP_BACKEND_URL

const addDetails = async (data) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      accept: "application/json",
    },
  };
  return await axios.post(`${apiURL}/addDetails`, data, config);
};

export const hairSTraightenerApi = {
  addDetails,
};
